import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '91, 119, 152',
		'primary-dark': '66, 89, 116',
		'accent': '255, 226, 0',
		'accent-plus': '0, 0, 0',
	},
});
