export default {
	cinema: {
		address: 'ул. Октябрьская, д. 43',
		place: null,
		phoneNumbers: ['8 (8452) 23-73-38'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/domkinosaratov',
		twitter: 'https://twitter.com/DomKino_Saratov',
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: 'https://itunes.apple.com/ru/app/дом-кино-билеты-в-кино/id1255958160',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.house',
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CBQWr8xs2D',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
